import React, {useState} from 'react'

export default function TextForm(props) {
    const handleUpClick = ()=>{
        // console.log("Uppercase was clicked" + text);
        let newText = text.toUpperCase();
        setText(newText)
        props.showAlert("Converted to Uppercase!", "success");
    }

    const handleLowClick = ()=>{
        // console.log("Uppercase was clicked" + text);
        let newText = text.toLowerCase();
        setText(newText)
        props.showAlert("Converted to Lowercase!", "success");

    }

    const handleClearClick = ()=>{
        // console.log("Uppercase was clicked" + text);
        let newText = "";
        setText(newText);
    }

    const handleOnChange = (event)=>{
        // console.log("On Change");
        setText(event.target.value);
    }

    const handleCopy =()=>{
        var text = document.getElementById("myBox");
        text.select();
        navigator.clipboard.writeText(text.value);
    }

    const handleExtraSpaces = ()=>{
        let newText = text.split(/[ ]+/);
        setText(newText.join(" "))
        props.showAlert("Extra Spaces has been removed", "success");
    }
    const [text, setText] = useState('');
    // setText("new text"); correct way to cange the state 
    return (
        <>
        <div className='container' style={{color: props.mode=== 'dark'?'white':'#042743'}} >
            <h2>{props.heading}</h2>
            <div className="mb-3">
                <textarea className="form-control" style={{backgroundColor: props.mode=== 'dark'?'#313D47':'light', color: props.mode=== 'dark'?'white':'#042743'}} id="myBox" value={text} onChange={handleOnChange} rows="8"></textarea>
            </div>
            <button className="btn btn-primary mx-2" onClick={handleUpClick}>Convert to Uppercase</button>
            <button className="btn btn-primary mx-2" onClick={handleLowClick}>Convert to Lowercase</button>
            <button className="btn btn-primary mx-2" onClick={handleExtraSpaces}>Remove Extra Space</button>
            <button className="btn btn-primary mx-2 clearText" onClick={handleClearClick}>Clear Text</button>
            <button className="btn btn-primary mx-2 clearText" onClick={handleCopy}>Copy Text</button>
            <hr />
        </div>
        <div className="container my-4" style={{color: props.mode=== 'dark'?'white':'#042743'}}>
            <h2>Your Text Summary</h2>
            <p>{text.split(" ").length} words and {text.length} characters</p>
            <h6>Expected Time:</h6>
            <p>{0.008 * text.split(" ").length} Minutes to Read.</p>
            <h2>Preview</h2>
            <p>{text.length>0?text:'Enter text to preview.'}</p>
        </div>
        </>
    )
}
